<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">OR Batch</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="batch_no"
                    label="Batch #"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="stab_from"
                    label="OR Stab from"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    type="number"
                    @change="auto_generation"
                    @keyup="auto_generation"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="stab_to"
                    label="OR Stab to"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    type="number"
                    @change="auto_generation"
                    @keyup="auto_generation"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="series_from"
                    label="OR Series from"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    type="number"
                    @change="auto_generation"
                    @keyup="auto_generation"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="series_to"
                    label="OR Series to"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-checkbox
                label="Is Kaagapay"
                v-model="is_kaagapay" hide-details
                class="me-3 mt-1"></v-checkbox>
            </v-card-text>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click="save_caller" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="mt-4">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">Batch #</th>
              <th class="text-center text-uppercase">Stab #</th>
              <th class="text-center text-uppercase">OR Series</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list_of_branch" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.batch_no }}
              </td>
              <td class="text-center">
                {{ item.stab_from+' - '+item.stab_to}}
              </td>
              <td class="text-center">
                {{ item.series_from+' - '+item.series_to}}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiAccountSearchOutline,
    mdiPencilBoxOutline
  } from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      cropped: require('@/assets/images/avatars/2.png'),

      saving: false,
      alert: false,
      is_kaagapay: false,
      alert_message: '',

      batch_no: '',
      stab_from: '',
      stab_to: '',
      series_from: '',
      series_to: '',


      list_of_branch: [],
      is_deleting: false,
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        show: false,
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
          mdiPencilBoxOutline,
        },
      }
    },
    mounted() {
      this.list_of_branches()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'month_of', 'user_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transmital_or', ['create_report_or_batches', 'list_of_report_or_batches']),
      list_of_branches() {
        this.list_of_report_or_batches({
          branch_id: this.branch_id,
        })
          .then(response => {
            this.list_of_branch = response.data
            this.batch_no = this.list_of_branch.length + 1
          })
          .catch(error => {
            console.log(error)
          })
      },
      auto_generation() {
        this.series_to = 0 + ''
        this.series_to = Math.floor((((parseFloat(this.stab_to) - parseFloat(this.stab_from)) + 1) * 50) + (parseFloat(this.series_from) - 1)) + ''
      },
      reset() {
        Object.assign(this.$data, initialState())
        this.list_of_branches()
      },
      save_caller() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          if (this.branch_id === 1) {
            this.alert = true
            this.alert_message = 'Please select Branch First!!!'
            this.saving = false
          } else {
            const data = new FormData()
            data.append('branch_id', this.branch_id)
            data.append('batch_no', this.batch_no)
            data.append('stab_from', this.stab_from)
            data.append('stab_to', this.stab_to)
            data.append('series_from', this.series_from)
            data.append('series_to', this.series_to)
            data.append('is_kaagapay', this.is_kaagapay ? 1 : 0)
            this.create_report_or_batches(data)
              .then(response => {
                var color = 'success'
                if (response.status === 201) {
                  color = 'error'
                } else {
                  this.reset()
                }
                this.change_snackbar({
                  show: true,
                  color: color,
                  text: response.data,
                })
              })
              .catch(error => {
                console.log(error)
                this.alert = true
                this.alert_message = error.response.data.message
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    },
  }
</script>
